/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, Link, Badge } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AttachMoney,
LocalShipping,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Settings as SettingsIcon,
Dashboard, Redeem, GetApp,
DoneAll,BorderAll, PeopleAlt,VerifiedUser,
ContactPhone, AddShoppingCart, AccountTree, Storefront as StorefrontIcon,
BusinessCenter as BusinessCenterIcon,
EventNote as EventNoteIcon, AccountBalance as AccountBalanceIcon,
ListAlt as ListAltIcon,ShoppingCart as ShoppingCartIcon,
Assignment as AssignmentIcon, ShoppingBasket, CastConnected as CastConnectedIcon,
Timeline as TimelineIcon, BubbleChart as BubbleChartIcon} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next'
import { Inventory as InventoryIcon, PointOfSale as PointOfSaleIcon, ProductionQuantityLimits as ProductionQuantityLimitsIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: colors.black,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
 itemNested2: {
   display: 'flex',
   paddingTop: 0,
   paddingBottom: 0,
   paddingLeft: theme.spacing(6)
 },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );
    var userData = dataService.getCurrentUserData();
    var posPrivilege = dataService.getCurrentUserPOSPrivilege();


    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openDelivery, setOpenDelivery] = React.useState(window.location.pathname.includes('delivery'));
    const handleClickDelivery = () => {
      setOpenDelivery(!openDelivery);
    };
    const [openFinance, setOpenFinance] = React.useState(window.location.pathname.includes('finance'));
    const handleClickFinance = () => {
        setOpenFinance(!openFinance);
    };
    const [openUsers, setOpenUsers] = React.useState(window.location.pathname.includes('user'));
    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
    };
    const [openZone, setOpenZone] = React.useState(window.location.pathname.includes('pricing'));
    const handleClickZone = () => {
        setOpenZone(!openZone);
    };
    const [openReports, setOpenReports] = React.useState(window.location.pathname.includes('report'));
    const handleClickReports = () => {
        setOpenReports(!openReports);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('settings'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openAddress, setOpenAddress] = React.useState(window.location.pathname.includes('address'));
    const handleClickAddress = () => {
        setOpenAddress(!openAddress);
    };
    const [openOrganization, setOpenOrganization] = React.useState(window.location.pathname.includes('org'));
    const handleClickOrganization = () => {
        setOpenOrganization(!openOrganization);
    };
    const [openPickup, setOpenPickup] = React.useState(window.location.pathname.includes('way'));
    const handleClickPickup = () => {
        setOpenPickup(!openPickup);
    };
    const [openMerchant, setOpenMerchant] = React.useState(window.location.pathname.includes('merchant'));
    const handleClickMerchant = () => {
        setOpenMerchant(!openMerchant);
    };
    const [openDeliveryman, setOpenDeliveryman] = React.useState(window.location.pathname.includes('deman'));
    const handleClickDeliveryman = () => {
        setOpenDeliveryman(!openDeliveryman);
    };
    const [openReporting, setOpenReporting] = React.useState(window.location.pathname.includes('report'));
    const handleClickReporting = () => {
        setOpenReporting(!openReporting);
    };
    const [openPos, setOpenPos] = React.useState(window.location.pathname.includes('pos'));
    const handleClickPos = () => {
      setOpenPos(!openPos);
    };
    const [openAccounting, setOpenAccounting] = React.useState(window.location.pathname.includes('accounting_'));
    const handleClickAccounting = () => {
      setOpenAccounting(!openAccounting);
    };
    const [openAccounts, setOpenAccounts] = React.useState(window.location.pathname.includes('accounts_'));
    const handleClickAccounts = () => {
      setOpenAccounts(!openAccounts);
    };
    const [openTransaction, setOpenTransaction] = React.useState(window.location.pathname.includes('transaction_'));
    const handleClickTransaction = () => {
      setOpenTransaction(!openTransaction);
    };
    const [openFinancial, setOpenFinancial] = React.useState(window.location.pathname.includes('financial_'));
    const handleClickFinancial = () => {
      setOpenFinancial(!openFinancial);
    };

    const [openPosOrder, setOpenPosOrder] = React.useState(window.location.pathname.includes('sale'));
    const handleClickPosSale = () => {
      setOpenPosOrder(!openPosOrder);
    };
    const [openPosPurchase, setOpenPosPurchase] = React.useState(window.location.pathname.includes('purchase'));
    const handleClickPosPurchase = () => {
      setOpenPosPurchase(!openPosPurchase);
    };
    const [openBooster, setOpenBooster] = React.useState(window.location.pathname.includes('booster'));
    const handleClickBooster = () => {
      setOpenBooster(!openBooster);
    };

    const [openShop, setOpenShop] = React.useState(window.location.pathname.includes('shops'));
    const handleClickShop = () => {
        setOpenShop(!openShop);
    };

    const [openProduct, setOpenProduct] = React.useState(window.location.pathname.includes('products'));
    const handleClickProduct = () => {
        setOpenProduct(!openProduct);
    };

    const [openInventory, setOpenInventory] = React.useState(window.location.pathname.includes('inventories'));
    const handleClickInventory = () => {
        setOpenInventory(!openInventory);
    };

    const [openBroadcast, setOpenBroadcast] = React.useState(window.location.pathname.includes('broadcast_'));
    const handleClickBroadcast = () => {
      setOpenBroadcast(!openBroadcast);
    };

  return (
          <div>

          { auth.includes('MANAGER_READ') &&
              <List {...rest} className={clsx(classes.root, className)}>
                  <ListItem className={classes.item} disableGutters >
                      <Button
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/organization_admin_dashboard'}
                      >
                    <div className={classes.icon}>{<Dashboard />}</div>
                      {t('menu.dashboard')}
                    </Button>
                  </ListItem>
              </List>
          }

        {/*<List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button} component={CustomRouterLink} to={'/shops_list'}>
                  <div className={classes.icon}>{<ShoppingBasket />}</div>
                    Shops management
                </Button>
            </ListItem>
        </List>*/}

        {/*<List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickShop();}}>
              {openShop ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ShoppingBasket />}</div>
                Shops management
              </Button>
            </ListItem>

            <Collapse in={openShop} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/shops_list'} >
                        Shops
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/shops_item_list'} >
                        Items
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>*/}

        { auth.includes('INVENTORY_PURCHASE_CREATE') &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickProduct();}}>
              {openProduct? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ProductionQuantityLimitsIcon />}</div>
                Product management
              </Button>
            </ListItem>

            <Collapse in={openProduct} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/*<ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_category_list'} >
                        Categories
                      </Button>
                    </ListItem>*/}

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_normal_item_list'} >
                        Items
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_addons_list'} >
                        Add-ons
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_normal_item_import'} >
                        Product import
                      </Button>
                    </ListItem>

                    {/*<ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/'} >
                        Productions
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/'} >
                        Discounts
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/'} >
                        Import products
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/'} >
                        Export products
                      </Button>
                    </ListItem>*/}
                </List>
            </Collapse>
        </List>
        }

        { auth.includes('INVENTORY_PURCHASE_CREATE') &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickInventory();}}>
              {openInventory? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<InventoryIcon />}</div>
                Inventory management
              </Button>
            </ListItem>

            <Collapse in={openInventory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_supplier_list'} >
                        Suppliers
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/pos_inventories_supplier_payment_list'} >
                        Supplier payment
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/purchase_order_list'} >
                        Purchase order
                      </Button>
                    </ListItem>

                    {/*<ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/purchase_order_list'} >
                        Purchase report
                      </Button>
                    </ListItem>*/}

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_purchase_item_list'} >
                        Purchase by item report
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_stock_quantity_logs'} >
                        Stock quantity change logs
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_stock_price_logs'} >
                        Price change logs
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_damage_list'} >
                        Damage list
                      </Button>
                    </ListItem>

                    {/*<ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/'} >
                        Stock adjustments
                      </Button>
                    </ListItem>*/}
                </List>
            </Collapse>
        </List>
        }

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPosSale();}}>
              {openPosOrder? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<PointOfSaleIcon />}</div>
                Sales management
              </Button>
            </ListItem>

            <Collapse in={openPosOrder} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/sales_sale_order'} >
                        Open sales order
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/sales_order_list'} >
                        Sales order list
                      </Button>
                    </ListItem>
                    {/*<ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/sales_order_list'} >
                        Sales report
                      </Button>
                    </ListItem>*/}
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/sales_order_items'} >
                        Sales by item report
                      </Button>
                    </ListItem>

                    { auth.includes('USER_ACCOUNT_CREATE') &&
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/sales_customer_list'} >
                        Customers
                      </Button>
                    </ListItem>
                    }
                      {auth.includes('FINANCE_READ') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/sales_order_payment_list'} >
                          {t('menu.pos.sales.customer.payment')}
                        </Button>
                      </ListItem>
                      }
                </List>
            </Collapse>
        </List>

        { auth.includes('FINANCE_READ') &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAccounting();}}>
                {openAccounting ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AccountBalanceIcon />}</div>
                  {t('menu.accounting')}
                </Button>
              </ListItem>

              <Collapse in={openAccounting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickAccounts();}}>
                        {openAccounts ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.accounts')}
                        </Button>
                      </ListItem>
                      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          { auth.includes('ACCOUNTING_ACCOUNT_BALANCE_READ') &&
                              <ListItem className={classes.itemNested2} disableGutters >
                                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                to={'/accounting_accounts_balance'} >
                                  {t('menu.accounts_balance')}
                                </Button>
                              </ListItem>
                          }
                          { auth.includes('ACCOUNTING_ACCOUNT_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_accounts_title_list'} >
                              {t('menu.account_name_title')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickTransaction();}}>
                        {openTransaction ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.transactions')}
                        </Button>
                      </ListItem>
                      <Collapse in={openTransaction} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_SIMPLE_TRANSACTION_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_simple_journal_entry'} >
                              {t('menu.simple_transaction')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_journal_entry/Non-Cash'} >
                              {t('menu.journal_voucher_entry')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_cash_entry/Cash'} >
                              {'Cash voucher entry'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_journal_voucher_list/Non-Cash'} >
                              {t('menu.general_journal_list')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_cash_voucher_list'} >
                              {'Cash voucher list'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_LEDGER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_general_ledger_list'} >
                              {t('menu.general_ledger_list')}
                            </Button>
                          </ListItem>
                          }

                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickFinancial();}}>
                        {openFinancial ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.financial_reports')}
                        </Button>
                      </ListItem>
                      <Collapse in={openFinancial} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_DAILY_ACCOUNTING_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_daily_accounting'} >
                              {t('menu.daily_accounting')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_SUMMARY_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_journal_summary'} >
                              {t('menu.journal_summary')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_TRIAL_BALANCE_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_trial_balance'} >
                              {t('menu.trial_balance')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_INCOME_STATEMENT_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_income_statement_list'} >
                              {t('menu.income_statement')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_BALANCE_SHEET_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_balance_sheet'} >
                              {t('menu.balance_sheet')}
                            </Button>
                          </ListItem>
                          }

                          { auth.includes('ACCOUNTING_PROFIT_LOSS_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_profit_and_loss'} >
                              {t('menu.profit_and_loss')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>
                  </List>
              </Collapse>
          </List>
        }

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickBroadcast();}}>
              {openBroadcast ? <MenuOpen /> : <MenuClose />}
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<CastConnectedIcon />}</div>
                    {'Broadcast message'}
                  </Button>
            </ListItem>

            <Collapse in={openBroadcast} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('BROADCAST_MESSAGE_CREATE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/broadcast_message_create'} >
                            {'Create message'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('BROADCAST_MESSAGE_LIST') &&
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/broadcast_message_list'} >
                        {'Message list'}
                      </Button>
                    </ListItem>
                    }

                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/broadcast_facebook_linking'} >
                            {'Facebook pages'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/broadcast_viber_linking'} >
                            {'Viber bots'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/broadcast_image_list'} >
                            {'Media files'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>

        {/*<List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickBooster();}}>
              {openBooster ? <MenuOpen /> : <MenuClose />}
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<BubbleChartIcon />}</div>
                    {'Booster'}
                  </Button>
            </ListItem>

            <Collapse in={openBooster} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_pages'} >
                            {'Facebook'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_viber_pages'} >
                            {'Viber'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>*/}

        { ( auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSettings();}}>
                {openSettings ? <MenuOpen /> : <MenuClose />}
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {t('menu.settings')}
                  </Button>
                </ListItem>
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_systems'} >
                            {t('menu.system_settings')}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('USER_ACCOUNT_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_branch/branch'} >
                            {'User accounts'}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('DELIVERY_COMPANY_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_shipping_services'} >
                            Shipping services
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('DELIVERY_COMPANY_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/shops_list'} >
                            Shop list
                          </Button>
                        </ListItem>
                        }

                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_currency'} >
                            Currencies
                          </Button>
                        </ListItem>

                    </List>
                </Collapse>
            </List>
        }

        { ( auth && auth.includes('LOGS_READ') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Link component="a" href="/audit_logs" >
                <Button
                    activeClassName={classes.active}
                    className={classes.button} >
                  <div className={classes.icon}>{<EventNoteIcon />}</div>
                    {t('menu.audit_logs')}
                </Button>
                </Link>
            </ListItem>
        </List>
        }

    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Link
                        component="a"
                        href="/toc.html"
                        target="_blank">
            <Button
                activeClassName={classes.active}
                className={classes.button} >
              <div className={classes.icon}>{<VerifiedUser />}</div>
                {t('menu.terms_conditions')}
            </Button>
            </Link>
        </ListItem>
    </List>

    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                onClick={event =>{dataService.removeTokenCookie();}}
            >
          <div className={classes.icon}>{<LogoutIcon />}</div>
            {t('menu.logout')}
          </Button>
        </ListItem>
    </List>

    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}